import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

import fourthSectionImage from './assets/fourth-section-image.jpg';
import { Heading, Paragraph, Button, Image } from 'components';

const Line = ({
  subTitle,
  title,
  description,
  buttonText,
  buttonAction,
  reversed = false,
  image,
  withBlueBackground = false
}) => (
  <div
    className={cx(styles.wrapper, {
      [styles.withBlueBackground]: withBlueBackground
    })}>
    <div className={cx(styles.line, { [styles.reversed]: reversed })}>
      <div className={styles.left}>{image}</div>
      <div className={styles.right}>
        <div className={styles.content}>
          <Paragraph size="small" color="blue" className={styles.subTitle}>
            {subTitle}
          </Paragraph>
          <Heading size="small" className={styles.title}>
            {title}
          </Heading>
          {description && (
            <Paragraph size="medium" className={styles.description}>
              {description}
            </Paragraph>
          )}
          {buttonText && (
            <Button
              size="medium"
              borderColor="blue"
              textColor="white"
              backgroundColor="blue"
              className={styles.button}
              onClick={buttonAction}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
);

const ProductDataSection = ({ openBecomeAPartnerModal }) => (
  <div className={styles.container}>
    <Line
      image={<Image src={fourthSectionImage} width="100%" />}
      subTitle="Detailed insight with data analytics"
      title="Our commitment to data and transparency means you can truly understand your audience. In-platform tools enable instant lead qualification and follow ups. There is no easier channel for measuring your ROI."
      buttonText="Become a Sponsor"
      buttonAction={openBecomeAPartnerModal}
    />
  </div>
);

export default ProductDataSection;
